import Util from "./util/Util";
const rootUrl = Util.checkHttps(process.env.REACT_APP_BE_URL);
const loginUrl = process.env.REACT_APP_LOGIN_BE_URL || "http://localhost:8000";

var APIUrl = {
  rootUrl: rootUrl,

  // User
  getUser: rootUrl + "/user", // GET
  checkIfUsernameExists: rootUrl + "/user/check/username/", // GET {username}
  updateUser: rootUrl + "/user/update", // POST {updatedField, updatedValue}
  getAdmin: rootUrl + "/user/getadmin", // GET {}
  addFavorite: rootUrl + "/user/favorite/add", //POST
  deleteFavorite: rootUrl + "/user/favorite/delete", //POST

  //Promo offer
  getPromoOperations: rootUrl + "/promo", // GET
  getPromoOperationFile: rootUrl + "/promo/file/", // POST{file}  

  // Rgpd
  getRgpd: rootUrl + "/rgpd/", // GET {}
  createRgpd: rootUrl + "/rgpd/create", // POST

  // Products
  getProducts: rootUrl + "/product", // GET
  getListProducts: rootUrl + "/product/list",
  getManyProducts: rootUrl + "/product/many", // POST {ids}
  getProductImg: rootUrl + "/product/img/", // GET {mercurialId, ref}
  getFamilyImg: rootUrl + "/product/fam/img/", // GET {mercurialId, fam}
  getFamilyImgDefault: rootUrl + "/product/fam/img/def/", // GET {mercurialId, fam}
  checkFamilyImg: rootUrl + "/product/fam/img/check/", // GET {mercurialId, fam}

  // Cart
  getCart: rootUrl + "/cart", // GET
  addProductToCart: rootUrl + "/cart/add", // POST {id_product, quantity}
  updateUrgent: rootUrl + "/cart/urgent", // POST {cartId, urgent}
  increaseQuantity: rootUrl + "/cart/inc", // POST {cartId}
  decreaseQuantity: rootUrl + "/cart/dec", // POST {cartId}
  deleteCartById: rootUrl + "/cart/delete", // POST {cartId}
  deleteAll: rootUrl + "/cart/delete/all", // POST

  // Order
  getOrders: rootUrl + "/order", // GET
  addOrder: rootUrl + "/order/add", // POST {comment}
  deleteOrder: rootUrl + "/order/delete", // POST {orderId}
  duplicate: rootUrl + "/order/duplicate", // POST {orderId}
  validate: rootUrl + "/order/validate", // POST {orderId}
  modifyunvalidated: rootUrl + "/order/modifyunvalidated", // POST {orderId}

  // PDFs
  toPDF: rootUrl + "/pdf", // POST {html, css=[], header, footer}

  // Notifications
  getNotifs: rootUrl + "/notifs", // GET
  deleteNotif: rootUrl + "/notifs/delete", // POST {notifId}

  // Residents
  getResidents: rootUrl + "/resident", // GET
  addResident: rootUrl + "/resident/add", // POST ({name, firstname,sexe})
  updateResident: rootUrl + "/resident/update", // POST ({name, firstname,sexe})
  deleteResident: rootUrl + "/resident/delete/", // POST {residentId}
  checkIfResidentExists: rootUrl + "/resident/check", // POST ({name, firstname})

  // Orders settings
  getOrdersSettings: rootUrl + "/settings/orders", // GET

  // Requests
  getRequests: rootUrl + "/requests", // GET
  addNewRequest: rootUrl + "/requests/add", // POST {material_type_id, method, precisions}
  getRequestFile: rootUrl + "/requests/file/", // URL {requestId} - POST {files}

  // Equipments
  getEquipments: rootUrl + "/equipment", // GET
  getEquipment: rootUrl + "/equipment/equipment", // POST {equipmentId}
  hasAlreadyRequest: rootUrl + "/equipment/requests/hasActive", // POST {equipmentId, requestType}
  updateEquipment: rootUrl + "/equipment/update", // POST {equipmentId, updatedField, updatedValue}
  getEquipmentFile: rootUrl + "/equipment/file/", // GET {equipmentId}/{fileName}
  getTemplate: rootUrl + "/equipment/template", // GET

  // EquipmentsRequest
  getEquipmentsRequest: rootUrl + "/equipmentsrequest/all", //GET ALL
  createEquipmentsRequest:  rootUrl +  "/equipmentsrequest/create",
  updateEquipmentsRequest:  rootUrl +  "/equipmentsrequest/update",
  deleteEquipmentsRequest:  rootUrl +  "/equipmentsrequest/delete",

  // Brand
  getBrands: rootUrl + "/brand", // GET

  // Model
  getModels: rootUrl + "/model", // GET

  // Mercurial
  getMercurial: rootUrl + "/mercurial",

  // Materials types
  getMaterialsTypes: rootUrl + "/materials", // GET
  getMaterialTypeImg: rootUrl + "/materials/img/", // GET URL {materialTypeId}

  // Checkpoints
  getCheckpoints: rootUrl + "/checkpoint", // GET

  // Maintenances
  getMaintenances: rootUrl + "/maintenance", // GET
  getMaintenanceById: rootUrl + "/maintenance/id", // POST {maintenanceId}
  getMaintenance: rootUrl + "/maintenance/establishment", // POST {establishmentId}
  toMaintenancePDF: rootUrl + "/maintenance/addFile",
  hasAlreadyMaintenance: rootUrl + "/maintenance/hasActive", // POST {equipmentId}
  getMaintenancesPDF: rootUrl + "/maintenance/file/", // GET URL    

  // Collaborator types
  getCollaboratorTypes: rootUrl + "/collaboratortype", // GET

  // Establishment settings
  getEstablishmentSettings: rootUrl + "/establishmentsettings", // GET

  // Establishment
  getEstablishment: rootUrl + "/establishment", // GET {}

  // Contact
  contact: rootUrl + "/contacts", // POST {object, message}

  // General settings
  getGeneralSettings: rootUrl + "/generalsettings", // GET
  getLogo: rootUrl + "/generalsettings/logo/", // URL {generalSettingsId} - GET

  // Clients
  getClients: rootUrl + "/client", // GET

  // Companies
  getCompany: rootUrl + "/company", // GET
  
  // Configurables
  getPaymentMethods: rootUrl + "/paymentmethods", // GET

  // Materiovigilance
  getMateriovigilance: rootUrl + "/materiovigilance", // GET {company_id}
  getMateriovigilanceFile: rootUrl + "/materiovigilance/files/", // POST {materiovigilance_id}

  // ======================================================================
  // ======================= ADMIN and SALES_REP ==========================
  // ======================================================================

  // Mercurials
  getMercurialsAdmin: rootUrl + "/admin/mercurial", // GET
  addMercurial: rootUrl + "/admin/mercurial/add", // POST {name, fileName, data, startDate, endDate}
  updateMercurial: rootUrl + "/admin/mercurial/update", // POST {mercurialId, data}
  syncWithCrm: rootUrl + "/admin/mercurial/crmsync",
  updateMercurialStatus: rootUrl + "/admin/mercurial/updatestatus", // POST {mercurialId, data}
  updateMercurialEndDate: rootUrl + "/admin/mercurial/update/endDate", // POST {_id, end_date}
  deleteMercurial: rootUrl + "/admin/mercurial/delete", // POST {mercurialId}
  addProductsImgs: rootUrl + "/admin/mercurial/imgs/products/", // URL {mercuriaId} - POST {imgs}
  removeProductsImgs: rootUrl + "/admin/mercurial/imgs/products/", // URL {mercuriaId} - POST {imgs}
  addFamilyImgs: rootUrl + "/admin/mercurial/imgs/families/", // URL {mercuriaId} - POST {imgs}
  removeFamilyImgs: rootUrl + "/admin/mercurial/imgs/remove/families/", // URL {mercuriaId} - POST {imgs}
  getMercImgOverview: rootUrl + "/admin/mercurial/img/overview/", // GET {mercurialId}
  
  // Orders
  getOrdersAdmin: rootUrl + "/admin/order", // GET
  addOrderAdmin: rootUrl + "/admin/order/add", // POST {comment, clientId}
  updateAdmin: rootUrl + "/admin/order/update", // POST {status}
  syncOrderMustAdmin: rootUrl + "/admin/must/savedossier3CE",
  syncOrderLomacoAdmin: rootUrl + "/admin/lomaco/savedossier3CE",
  deleteOrderAdmin: rootUrl + "/admin/order/delete", // POST {orderId}
  splitOrderAdmin: rootUrl + "/admin/order/split", // POST {orderId, productsAvailable, productsUnavailable}
  duplicateAdmin: rootUrl + "/admin/order/duplicate", // POST {orderId}
  
  // Clients
  getClientsAdmin: rootUrl + "/admin/client", // GET
  addClient: rootUrl + "/admin/client/add", // POST {username, name, firstname, function, email, validator}
  updateClient: rootUrl + "/admin/client/update", // POST {clientId, updatedField, updatedValue}
  deleteClient: rootUrl + "/admin/client/delete", // POST {clientId}
  
  // Establishments
  getEstablishments: rootUrl + "/admin/establishment", // GET
  getEstablishmentById: rootUrl + "/admin/establishment/establishment", // POST {establishmentId}
  addEstablishment: rootUrl + "/admin/establishment/add", // POST {name, email, phone, address, address_compl, postal_code, city, website}
  updateEstablishment: rootUrl + "/admin/establishment/update", // POST {establishmentId, updatedField, updatedValue}
  
  // Establishment types
  getEstablishmentTypes: rootUrl + "/admin/establishmenttype", // GET
  addEstablishmentType: rootUrl + "/admin/establishmenttype/add", // POST {name}

  // Payment methods
  addPaymentMethod: rootUrl + "/admin/paymentmethods/add", // POST {name}

  // Payment deadlines
  getPaymentDeadlines: rootUrl + "/admin/paymentdeadlines", // GET
  addPaymentDeadline: rootUrl + "/admin/paymentdeadlines/add", // POST {name}

  // Establishment settings
  getEstablishmentsSettings: rootUrl + "/admin/establishmentsettings/all", // GET
  updateEstablishmentSettings: rootUrl + "/admin/establishmentsettings/update", // POST {establishmentId, updatedField, updatedValue}

  // Products
  getProductsAdmin: rootUrl + "/admin/product", // GET
  getListProductsAdmin: rootUrl + "/admin/product/list",
  getProductsByMercurialIdAdmin: rootUrl + "/admin/product/mercurial/",
  getProductsByEstablishmentId: rootUrl + "/admin/product/establishment/",
  updateProductAdmin: rootUrl + "/admin/product/update", // POST {productId, updatedField, updatedValue}

  // Cart
  addProductToCartAdmin: rootUrl + "/admin/cart/add", // POST {id_product, quantity}

  // Requests
  addNewRequestAdmin: rootUrl + "/admin/requests/add", // POST
  getAllRequests: rootUrl + "/admin/requests", // GET
  updateRequest: rootUrl + "/admin/requests/update", // POST {requestId, updatedField, updatedValue}
  deleteRequest: rootUrl + "/admin/requests/delete", // DELETE
  deleteRequestFile: rootUrl + "/admin/requests/file/delete",
  addRequestFiles: rootUrl + "/admin/requests/files/", // URL {requestId} - POST {files}
  
  // Residents
  getAllResidents: rootUrl + "/admin/resident/all", // GET
  addResidentAdmin: rootUrl + "/admin/resident/add", // POST ({name, firstname,sexe})
  updateResidentAdmin: rootUrl + "/admin/resident/update", // POST ({name, firstname,sexe})
  deleteResidentAdmin: rootUrl + "/admin/resident/delete/", // POST {residentId}
  checkIfResidentExistsAdmin: rootUrl + "/admin/resident/check", // POST ({name, firstname, establishmentId})

  // Equipment
  getAllEquipments: rootUrl + "/admin/equipment/all", // GET
  getEstablishementEquipments: rootUrl + "/admin/equipment/", // GET {establishmentId}
  getUnassignedEquipments: rootUrl + "/admin/equipment/unassigned", // GET
  addEquipment: rootUrl + "/admin/equipment/add", // POST {establishment_id, internal_ref, material_type_id, brand, model, serial_nbr, purchase_date, warranty, affectation, resident_id, floor, room, owner, state, materiovigilance, misc}
  importEquipments: rootUrl + "/admin/equipment/import", // POST {equipmentId}
  checkIfEquipmentExists: rootUrl + "/admin/equipment/check", // POST {establishmentId, internRef}
  updateEquipmentAdmin: rootUrl + "/admin/equipment/update", // POST {equipmentId, updatedField, updatedValue}
  deleteEquipment: rootUrl + "/admin/equipment/delete", // POST {equipmentId}
  addEquipmentFiles: rootUrl + "/admin/equipment/files/", // URL {equipmentId} - POST {files}
  deleteEquipmentFileById: rootUrl + "/admin/equipment/file/delete", // DELETE {equipmentId}/{fileName}
  assignEquipment: rootUrl + "/admin/equipment/assign", // POST {equipmentId, establishment_id}
  unassignEquipment: rootUrl + "/admin/equipment/unassign", // POST {equipmentId}
  
  // Brand
  addBrand: rootUrl + "/admin/brand/add", // POST {name}
  updateBrand: rootUrl + "/admin/brand/update", // POST {brandId, updatedField, updatedValue}
  removeBrand: rootUrl + "/admin/brand/remove",
  
  // Model
  addModel: rootUrl + "/admin/model/add", // POST {brand_id, name}
  updateModel: rootUrl + "/admin/model/update", // POST {modelId, updatedField, updatedValue}
  removeModel: rootUrl + "/admin/model/remove",

  // Maintenances
  addMaintenance: rootUrl + "/admin/maintenance/add", // POST {equipmentId, checkpoints}
  deleteMaintenance: rootUrl + "/admin/maintenance/delete", // POST {maintenanceId}

  // Settings orders
  getOrdersSettingsAdmin: rootUrl + "/admin/settings/orders", // GET
  updateOrderSettingAdmin: rootUrl + "/admin/settings/orders/update", // POST {orderSettingId, updatedField, updatedValue}
  updateShippingCostsSetting: rootUrl + "/admin/settings/orders/shipping/update", // POST {shippingCosts}

  // Materovigilance
  addMateriovigilance: rootUrl + "/admin/materiovigilance", // POST {equipement}
  deleteMateriovigilance: rootUrl + "/admin/materiovigilance/delete", // POST {materiovigilance_id}
  updateMateriovigilance: rootUrl + "/admin/materiovigilance/update", // POST {materiovigilance_id}
  postDocMateriovigilance: rootUrl + "/admin/materiovigilance/doc/", // POST {materiovigilance_id, doc}
  removeMateriovigilanceFile: rootUrl + "/admin/materiovigilance/files/delete/", // POST {materiovigilance_id}
  
  // API MUST
  mustFindCli: rootUrl + "/admin/must/client", // GET
  mustFindArticles: rootUrl + "/admin/must/article", // GET
  syncOrderAdmin: rootUrl + "/admin/must/savedossier3CE",
  mustGetEtatDossier: rootUrl + "/admin/must/getetatdossier", //GET
  
  // ======================================================================
  // ============================= ADMIN ==================================
  // ======================================================================

  // Material types
  addMaterialsType: rootUrl + "/admin/materials/add", // POST {name}
  deleteMaterialType: rootUrl + "/admin/materials/delete", // POST {materialId}
  updateMaterialType: rootUrl + "/admin/materials/update", // POST {materialTypeId, fieldName, fieldValue}
  addMaterialTypeImg: rootUrl + "/admin/materials/file/", // URL {MaterialTypeId} - POST {file}
  deleteMaterialTypeImg: rootUrl + "/admin/materials/img/delete",
  sortCheckpoint: rootUrl + "/admin/materials/checkpoint/sort", // POST
  addCheckpointToMT: rootUrl + "/admin/materials/checkpoint/add", // POST {materialTypeId, checkpoint}
  updateCheckpoint: rootUrl + "/admin/materials/checkpoint/update", // POST {checkpoint}
  deleteCheckpointFromMT: rootUrl + "/admin/materials/checkpoint/delete", // POST {materialTypeId, checkpoint}

  //Promo offer
  createPromoOperation: rootUrl + "/admin/promo/add", // POST{product_id, mercurial_id, startDate, endDate}
  addPromoOperationFile: rootUrl + "/admin/promo/file/", // POST{file}
  updatePromoOperation: rootUrl + "/admin/promo/update/", // POST{updatedField, updatedValue, promoOperationId}
  deletePromoOperation: rootUrl + "/admin/promo/delete/", // POST{operationPromotionalId}
  getPromoOperationsAdmin: rootUrl + "/admin/promo", // GET
  
  // Collaborator types
  addCollaboratorType: rootUrl + "/admin/collaboratortype/add", // POST {name}
  removeCollaboratorType: rootUrl + "/admin/collaboratortype/remove", // POST {name}
  
  // Collaborators
  getCollaborators: rootUrl + "/admin/collaborator", // GET
  addCollaborator: rootUrl + "/admin/collaborator/add", // POST {data}
  updateCollaborator: rootUrl + "/admin/collaborator/update", // POST {collaboratorId, updatedField, updatedValue}
  removeCollaborator: rootUrl + "/admin/collaborator/remove/", // POST {collaboratorId}
  addCollaboratorEstablishment: rootUrl + "/admin/collaborator/establishment/add", // POST {collaboratorId, establishmentId}
  removeCollaboratorEstablishment: rootUrl + "/admin/collaborator/establishment/remove", // POST {collaboratorId, establishmentId}
  
  // General settings
  updateGeneralSettings: rootUrl + "/admin/generalsettings/update", // POST {updatedField, updatedValue}
  addLogo: rootUrl + "/admin/generalsettings/logo/", // URL {generalSettingsId} - POST {data}

  //API LOMACO
  lomacoFindArticles: rootUrl + "/admin/lomaco/article", //GET
  lomacoFindCli: rootUrl + "/admin/lomaco/client",   //GET
  lomacoGetEtatDossier: rootUrl + "/admin/lomaco/getorderstatus",

  // Aide en ligne
  getTokenOnlineHelp: loginUrl + "/onlinehelp/connect",
};

var sessionJWT = sessionStorage.getItem("jwt");
if (sessionJWT) APIUrl.jwtToken = sessionJWT;

export default APIUrl;
