import React from "react";
import OrdersAdmin from "../orders/admin/OrdersAdmin";
import Estimates from "../estimates/Estimates";
import RequestsAdmin from "../requests/admin/RequestsAdmin";
import Establishments from "../clients/Establishments";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { Alert, Card, Modal } from "react-bootstrap";
import Icon from "../sub/Icon";
import EquipmentsRequest from "../fleet/equipmentRequest/EquipmentsRequest";
import { connect } from "react-redux";
import MercurialStatus from "../../enums/MercurialStatus";

class SplitHomeAdmin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
    };
  }

  closeModal() {
    this.setState({ modal: null });
  }

  openEquipmentsRequestModal() {
    this.setState({
      modal: (
        <Modal
          show={true}
          onHide={() => this.closeModal()}
          backdrop={"static"}
          size="xl"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <FormattedMessage id="Summary.Equipments.Request" />
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <EquipmentsRequest close={() => this.closeModal()} />
          </Modal.Body>
        </Modal>
      ),
    });
  }

  isWithinNext30Days(dateString) {
    const inputDate = new Date(dateString);
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const thirtyDaysFromNow = new Date();
    thirtyDaysFromNow.setDate(today.getDate() + 30);

    return inputDate >= today && inputDate <= thirtyDaysFromNow;
  }

  render() {
    const {mercurials} = this.props;

    let hasMercurialToExtend = false;

    if (mercurials && mercurials.length !== 0) {
        for (const mercurial of mercurials) {
          const isOutDated = new Date(mercurial.end_date) < new Date();
          const isInactive = mercurial.status === MercurialStatus.INACTIVE;
      
            if (!isInactive && !isOutDated && this.isWithinNext30Days(mercurial.end_date)) {
                hasMercurialToExtend = true;
                break;
            }
        }
    }
    return (
      <React.Fragment>
        {hasMercurialToExtend && <div className="row">
          <div className="col-12">
            <Alert variant="warning"><Icon icon="clock" /> <FormattedMessage id="Mercurial.Extend.Info" /></Alert>
          </div>
        </div>}

        <div className="row">
          <div className="col-12 col-lg-6">
            <div className="m-2 zoom-7">
              <Card className="p-3">
                <Card.Title>
                  <h4>
                    <FormattedMessage id="Summary.Orders.Admin" />
                  </h4>
                </Card.Title>
                <OrdersAdmin limit={5} />
                <Link to="/home/orders" className="overlay text-center">
                  <Icon icon="magnifying-glass" />
                </Link>
              </Card>
            </div>
          </div>

          <div className="col-12 col-lg-6">
            <div className="m-2 zoom-7">
              <Card className="p-3">
                <Card.Title>
                  <h4>
                    <FormattedMessage id="Summary.Estimates.Admin" />
                  </h4>
                </Card.Title>
                <Estimates limit={5} />
                <Link to="/home/estimates" className="overlay text-center">
                  <Icon icon="magnifying-glass" />
                </Link>
              </Card>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-lg-6 ">
            <div className="m-2 zoom-7">
              <Card className="p-3">
                <Card.Title>
                  <h4>
                    <FormattedMessage id="Summary.Clients.Admin" />
                  </h4>
                </Card.Title>
                <Establishments limit={5} />
                <Link to="/home/clients" className="overlay text-center">
                  <Icon icon="magnifying-glass" />
                </Link>
              </Card>
            </div>
          </div>

          <div className="col-12 col-lg-6 ">
            <div className="m-2 zoom-7">
              <Card className="p-3">
                <Card.Title>
                  <h4>
                    <FormattedMessage id="Summary.Requests.Admin" />
                  </h4>
                </Card.Title>
                <RequestsAdmin limit={5} />
                <Link to="/home/requests" className="overlay text-center">
                  <Icon icon="magnifying-glass" />
                </Link>
              </Card>
            </div>
          </div>

          <div className="col-12 col-lg-6 ">
            <div className="m-2 zoom-7">
              <Card
                className="p-3"
                onClick={() => this.openEquipmentsRequestModal()}
              >
                <Card.Title>
                  <h4>
                    <FormattedMessage id="Summary.Equipments.Request" />
                  </h4>
                </Card.Title>
                <EquipmentsRequest limit={5} />
                <Link to="/home" className="overlay text-center">
                  <Icon icon="magnifying-glass" />
                </Link>
              </Card>
            </div>
          </div>
        </div>
        {this.state.modal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mercurials: state.mercurials,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SplitHomeAdmin);

